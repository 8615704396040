import mdiSvg from './mdi-svg'
import md from './md'
import mdi from './mdi'
import fa from './fa'
import fa4 from './fa4'

export default Object.freeze({
  mdiSvg,
  md,
  mdi,
  fa,
  fa4,
})
